<template>
  <div>
    <!-- dialog -->
    <!-- :before-close="(showMessageDialog = false)" -->
    <van-datetime-picker
      cancel-button-text="取消"
      confirm-button-text="确认"
      v-model="date"
      type="datetime"
      v-if="showD"
      @cancel="showD = false"
      @confirm="setFTome"
      :min-date="minDate"
    />
    <van-datetime-picker
      cancel-button-text="取消"
      confirm-button-text="确认"
      v-model="date2"
      type="datetime"
      v-if="showD2"
      @cancel="showD2 = false"
      @confirm="setFTome2"
      :min-date="minDate"
    />
    <van-dialog
      confirm-button-text="确认"
      v-model="showMessageDialog"
      title="变更密码"
    >
      {{ showMessageMsg }}
    </van-dialog>

    <van-popup v-model="bankSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="bankSelect = false"
        confirm-button-text="确认"
        cancel-button-text="取消"
      />
    </van-popup>
    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      :before-close="delBankBefore"
      v-model="delBankModal"
      title="删除"
      show-cancel-button
    >
      <p style="text-align: center; color: red; padding: 15px">确认删除</p>
      <p style="text-align: center; padding: 15px; padding-top: 0px">
        {{ currentData.code }}
      </p>
    </van-dialog>
    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      :before-close="editBankBefore"
      v-model="editBankModal"
      title="编辑"
      show-cancel-button
    >
      <van-form refs v-if="currentData.setting?.rates">
        <van-field
          v-model="currentData.expired_at"
          :name="'过期时间'"
          :label="'过期时间'"
          :placeholder="'过期时间'"
          :rules="[{ required: false, message: '过期时间' }]"
          @click="showD2 = true"
        />
        <van-field
          v-for="rate in $store.state.selfData.rates"
          :key="rate"
          v-model="currentData.setting.rates[rate]"
          :name="rate"
          :label="channelList[rate] + '费率'"
          placeholder="0.01"
          required
          :rules="[{ required: true, message: rate }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      v-model="activeInvitesDialog"
      title="激活帐号"
    >
      <van-cell v-for="user in currentData" :key="user">
        <template #title>
          <span v-if="activeInvitesDialog" class="custom-title"
            >用户: {{ user.username }}</span
          >
        </template>
        <template #right-icon>
          <van-button
            @click="activeAcc(user.user_id)"
            style="height: 25px"
            type="primary"
            >激活</van-button
          >
        </template>
      </van-cell>
    </van-dialog>
    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      :before-close="addBankBefore"
      v-model="addBankModal"
      title="新增邀请码"
      show-cancel-button
      class="addInvitesDialog"
    >
      <template #title>
        <div>
          <p style="text-align: left; width: 100%; padding-left: 5px">
            新增邀请码
          </p>
        </div>
      </template>
      <img class="invitesI" src="/new/invitesI.png" alt="" />
      <van-form ref="addBankPForm">
        <van-field
          v-model="addBankData.date"
          :name="'过期时间'"
          :label="'过期时间'"
          placeholder="2020-01-01 00:00:00"
          required
          :rules="[{ required: true, message: '过期时间' }]"
          @click="showD = true"
        />
        <div v-if="addBankModal && $store.state.selfData.rates">
          <van-field
            v-for="rate in $store.state.selfData.rates"
            :key="rate"
            v-model="addBankData.setting.rates[rate]"
            :name="rate"
            :label="channelList[rate] + '费率'"
            placeholder="0.01"
            required
            :rules="[{ required: true, message: rate }]"
          />
        </div>
        <van-field
          v-model="addBankData.sub_limit"
          :name="'可创下线数量'"
          :label="'可创下线数量'"
          placeholder="1"
          required
          :rules="[{ required: true, message: '可创下线数量' }]"
        />
      </van-form>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar
        class="topNavbar"
        @click-left="onClickLeft"
        title="邀请码"
        left-arrow
      >
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <template #right>
          <div
            class="addAcc"
            @click="
              addBankModal = true;
              addBankData = { type: 0, qr_string: '', setting: { rates: {} } };
              qrcode = [];
            "
          >
            <van-icon class="plus" name="plus" />新增
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->

    <van-pull-refresh
      :head-height="100"
      :pulling-text="'下拉刷新'"
      :loosing-text="'释放刷新'"
      :loading-text="'加载中'"
      @refresh="onRefresh"
      v-model="isLoading"
      :disabled="!inTop"
    >
      <div class="wrap">
        <div class="tableWrap" v-for="list in bankList" :key="list.id">
          <p class="tableContent">
            <span class="tableTitle"> 邀请码 </span>
            <span
              class="tablesContent"
              style="display: flex; align-items: center"
            >
              {{ list.code }}
              <van-button
                size="normal"
                block
                plain
                style="
                  background-color: #4065f0;
                  width: 45px;
                  color: white;
                  height: 22px;
                  font-size: 12px;
                  white-space: nowrap;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                v-clipboard:copy="copyText(list.code)"
                v-clipboard:success="onCopy"
                type="primary"
                >复制</van-button
              >
            </span>
          </p>
          <div v-if="list.setting?.rates" style="width: 100%">
            <p
              class="tableContent"
              v-for="rate in Object.keys(list.setting.rates)"
              :key="rate"
            >
              <span class="tableTitle"> {{ channelList[rate] }}费率</span>
              <span class="tablesContent">
                {{ list.setting.rates[rate] }}
              </span>
            </p>
          </div>
          <p class="tableContent">
            <span class="tableTitle"> 过期时间 </span>
            <span class="tablesContent">
              {{ list.expired_at }}
            </span>
          </p>
          <p class="tableContent">
            <span class="tableTitle"> 注册人数 </span>
            <span class="tablesContent" v-if="list.regist">
              {{ Object.keys(list.regist).length }}
            </span>
          </p>
          <p class="tableContent">
            <span class="tableTitle"> 未激活 </span>
            <span
              class="tablesContent"
              v-if="list.regist"
              style="display: flex; align-items: center"
            >
              {{
                Object.values(list.regist).filter((e) => e.is_active == 0)
                  .length
              }}
              <van-button
                size="normal"
                block
                plain
                style="
                  background-color: #4065f0;
                  width: 45px;
                  color: white;
                  height: 22px;
                  font-size: 12px;
                  white-space: nowrap;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                v-if="
                  Object.values(list.regist).filter((e) => e.is_active == 0)
                    .length
                "
                @click="activeInvites(list)"
                type="primary"
                >查看</van-button
              >
            </span>
          </p>

          <p class="tableContent">
            <span class="tableTitle"> 启用状态 </span>
            <span class="tablesContent" style="display: flex">
              <van-switch
                v-if="list.status != 2"
                size="24"
                active-color="#3FBBFE"
                inactive-color="#cccccc"
                :value="list.status == 1"
                @input="changeEnable(list)"
              />
              <span v-else style="color: red">待审核</span>
            </span>
          </p>
          <!-- <p class="tableContent">
            <span class="tableTitle"> 备注 </span>
            <span class="tablesContent"> {{ list.note }} </span>
          </p> -->
          <div class="editBtn">
            <p
              @click="editBank(list)"
              :style="{ color: $returnColor().color3 }"
            >
              编辑
            </p>
            <p style="color: red" @click="delBank(list)">删除</p>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "vant";
export default {
  // components: { Multiselect },
  data() {
    return {
      showMessageMsg: "",
      showMessageDialog: false,
      inTop: false,
      isLoading: false,
      channelList: {
        bank: "银联",
        alipay: "支付宝",
        "alipay-hb": "支付宝红包",
        ecny: "数字人民币",
        wechat: "微信",
        titok: "抖音红包",
      },
      bankSelect: false,
      show: true,
      newPassword: "",
      password: "",
      paginate: {},
      bankList: [],
      addBankModal: false,
      editBankModal: false,
      addBankData: { type: 0 },
      currentPage: 1,
      currentData: {},
      banks: [],
      files: [],
      delBankModal: false,
      open: false,
      next_page: true,
      wait: false,
      qrcode: [],
      editQrString: "",
      editQrFile: [],
      showD: false,
      showD2: false,
      minDate: new Date(),
      date: "",
      activeInvitesDialog: false,
    };
  },
  watch: {
    currentPage() {
      // this.getBank();
    },
  },
  methods: {
    activeAcc(user) {
      this.$dialog
        .confirm({
          title: "激活帐号",
          message: `确认激活 ${user}`,
        })
        .then(() => {
          this.$http.get(`/mobile/activeInvites/${user}`).then((res) => {
            if (res.data.code == 0) {
              Toast.success("成功");
              this.getBank();
              this.currentData = this.currentData.filter((e) => {
                return e != user;
              });
            }
          });
        });
    },
    activeInvites(list) {
      let data = JSON.parse(JSON.stringify(list)).regist;
      console.log(data);
      this.currentData = data.filter((e) => {
        return e.is_active == 0;
      });
      console.log(this.currentData);
      this.activeInvitesDialog = true;
    },
    copyText(code) {
      return code;
      // return window.location.origin + "/register?code=" + code;
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    setFTome(d) {
      this.addBankData.date = this.$returnTime(d, "all") + ":59";
      this.showD = false;
    },
    setFTome2(d) {
      this.currentData.expired_at = this.$returnTime(d, "all") + ":59";
      this.showD2 = false;
    },
    onRefresh() {
      this.bankList = [];
      this.getBank();
      this.isLoading = false;
    },
    delBank(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.delBankModal = true;
    },
    onConfirm(value) {
      this.addBankData.bank_name = value;
      this.bankSelect = false;
    },
    editBank(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.editQrString = "";
      this.editQrFile = [];
      this.editBankModal = true;
    },
    changeEnable(list) {
      let currentData = JSON.parse(JSON.stringify(list));
      this.$dialog
        .confirm({
          cancelButtonText: "取消",
          confirmButtonText: "确认",
          title: "提醒",
          message: `是否${list.status == "1" ? "关闭" : "开启"}邀请码}？`,
        })
        .then(() => {
          currentData.status = list.status == "1" ? 0 : 1;
          this.$http
            .put(`/mobile/invites/${list.code}`, {
              status: currentData.status,
            })
            .then((res) => {
              if (res.data.code == 0) {
                list.status == "1" ? (list.status = 0) : (list.status = 1);
              }
            });
        });
    },
    getBank() {
      this.$http.get(`/mobile/invites?page=${this.currentPage}`).then((res) => {
        if (res.data.code == 0) {
          let getDataData = JSON.parse(JSON.stringify(res.data.data));
          if (typeof getDataData == "object") {
            if (!Array.isArray(getDataData)) {
              getDataData = Object.values(getDataData);
            }
          }
          this.wait = false;
          this.next_page = getDataData.length;
          getDataData.length
            ? (this.next_page = true)
            : (this.next_page = false);
          let listData = JSON.parse(JSON.stringify(this.bankList));
          if (this.currentPage == 1) {
            listData = [];
            this.bankList = [];
          }
          getDataData.forEach((data) => {
            if (this.$cookie.get(data.bank_no) && data.gcash_status == 4) {
              try {
                data.gcash_status = JSON.parse(
                  this.$cookie.get(data.bank_no)
                ).gcash_status;
              } catch {
                console.error("error");
              }
            }
            let have = false;
            this.bankList.forEach((e) => {
              if (e.id == data.id) {
                have = true;
              }
            });
            if (!have) {
              listData.push(data);
            }
          });
          this.bankList = listData;
        }
      });
    },
    delBankBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .delete(`/mobile/invites/${this.currentData.code}`)
          .then((res) => {
            if (res.data.code == 0) {
              this.getBank();
              this.delBankModal = false;
              Toast.success("成功");
            }
          });
      } else {
        done();
      }
      done();
    },
    async addBankBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        let data = JSON.parse(JSON.stringify(this.addBankData));
        let sendData = {
          setting: {
            rates: {
              ...data.setting.rates,
            },
            sub_limit: data.sub_limit,
          },
          expired_at: data.date,
        };
        this.$http.post(`/mobile/invites`, sendData).then((res) => {
          if (res.data.setting) {
            this.files = [];
            vm.addBankModal = false;
            vm.getBank();
            Toast.success("成功");
          } else {
            done(false);
            alert(res.data.message);
          }
        });
        done(false);
      } else {
        done();
        return;
      }
    },
    editBankBefore(method, done) {
      if (method == "confirm") {
        let data = JSON.parse(JSON.stringify(this.currentData));
        this.$http
          .put(`/mobile/invites/${this.currentData.code}`, data)
          .then((res) => {
            if (res.data.code == 0) {
              this.bankList.forEach((e, index) => {
                if (e.id == this.currentData.id) {
                  this.bankList[index].ds_limit = this.currentData.ds_limit;
                  this.bankList[index].ds_limit_month =
                    this.currentData.ds_limit_month;
                  this.bankList[index].keep_balance =
                    this.currentData.keep_balance;
                }
              });
              this.editBankModal = false;
              Toast.success("成功");
            }
          });
        done(false);
      } else {
        done();
        return;
      }
    },
    onClickLeft() {
      this.$router.push(this.$cookie.get("prevPage") || "/");
    },
    scrollPage() {
      this.inTop = window.scrollY == 0;
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          if (this.wait) return;
          this.wait = true;
          this.currentPage += 1;
          this.getBank();
        }
      }
    },
  },
  created() {
    this.getBank();
    this.$http.get(`/mobile/banks`).then((res) => {
      this.banks = res.data.data;
    });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
  computed: {
    ...mapState([
      "showOverlay",
      // ...
    ]),
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  margin-top: 0px !important;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tableWrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 355px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  .tableContent {
    width: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 44px;
    border-bottom: 1px solid #eaeaea;
    justify-content: space-between;
    .tableTitle {
      font-weight: 400;
      white-space: nowrap;
      width: 60px;
      text-align: left;
      margin-right: 10px;
      font-weight: bold;
    }
    .tablesContent {
      color: #999999;
      word-break: break-all;
      display: flex;
      align-items: center;
    }
  }
}

.addAcc {
  display: flex;
  align-items: center;
  // color: #009aaa;
  font-size: 14px;
  .plus {
    color: #009aaa !important;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
    //
  }
}
.editBtn {
  height: 45px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  p {
    width: 49%;
    line-height: 35px;
    font-size: 12px;
    height: 35px;
    background-color: #f1f5fe;
    color: #4065f0;
    border-radius: 100px;
    margin-top: 10px;
    &:last-child {
      background-color: rgb(255, 219, 220);
      color: #f04040;
    }
  }
}
.danger {
  color: red;
  margin-bottom: 5px;
}
.invitesI {
  position: absolute;
  width: 200px;
  top: -53px;
  right: 10px;
  z-index: -1;
}
</style>
<style lang="scss">
.addDialog {
  .van-dialog__content {
    height: 70vh;
    overflow: scroll;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
